import React from 'react';
import { HeroBackground } from '@vp/digital-hero-lib';
import {
  BannerTextContainer,
  BannerText,
  BannerTitle,
  BannerDescription,
  Banner,
} from '@vp/swan';

interface HeroProps {
  mfeName: string;
  buildTime: string;
}

const Hero = ({ mfeName, buildTime }: HeroProps) => (
  <Banner
    textBoxHorizontalAlign="center"
    variant="full-width-image"
    height="short"
  >
    <BannerTextContainer>
      <BannerText>
        <BannerText bgc="white">
          <BannerTitle component="h1">{mfeName} Page Index</BannerTitle>
          <BannerDescription>
            Click on on any button below to visit a specific page. Build time{' '}
            {buildTime}
          </BannerDescription>
        </BannerText>
      </BannerText>
    </BannerTextContainer>
    <HeroBackground backgroundClassName="index-hero__background" />
  </Banner>
);

export default Hero;
